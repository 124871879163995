<template>
  <div class="bg-white rounded shadow">
    <div class="pb-4 pt-8 rounded-tl rounded-tr" :class="['bg-gradient-' + colour]">
      <div class="uppercase ml-4 text-lg font-semibold border-b border-white-transparent pb-3 text-white opacity-90">
        {{ status }}
      </div>
      <div class="text-10xl px-4 text-white font-light">
        {{ percent }}<span class="text-sm text-bold text-2xl opacity-75">%</span>
      </div>
    </div>
    <div class="flex">
      <div class="flex-grow px-4 pt-4 pb-6">
        <tooltip :text="detailedTime" position="right" class="inline-block uppercase text-xs font-bold tracking-wide text-grey">
          {{ humanTime }}
        </tooltip>
        <a :href="url" target="_blank" class="block no-underline pb-2 text-xs font-bold text-grey flex-grow">
          {{ url }}
        </a>
        <div class="pt-2 uppercase font-bold text-grey">
          {{ name }}
        </div>
      </div>
      <div class="bg-grey-light flex p-4 rounded-br">
        <icon name="down" />
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '../partials/Icon';
  import Tooltip from '../partials/Tooltip';
  export default {
    name: 'ScanResult',
    components: { Tooltip, Icon },
    props: ['colour', 'percent', 'human-time', 'detailed-time', 'url', 'name', 'status']
  };
</script>

<style scoped>

</style>
