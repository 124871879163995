export default class Utilities
{
  static wait(time = 500) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  static transformApiErrors(apiErrors) {
    return _.map(apiErrors, (error) => {
      return {
        field: null,
        msg: error
      }
    });
  }
}
