<template>
  <notice class="md:flex items-center" type="info" :can-close="true" @close="$emit('close')">
    <div class="mb-4 md:mb-0 flex-grow">
      <div class="mb-2 md:mb-0">Would you like a copy of your results sent to you?</div>
      <div class="text-xs">We'll also save the scan results to this email</div>
    </div>
    <form @submit.prevent="submit" class="lg:flex md:w-1/2">
      <input-container icon="letter" class="lg:rounded-br-none lg:rounded-tr-none flex-grow">
        <input type="email" v-model="form.email" placeholder="email@example.com" />
      </input-container>
      <btn class="btn-blue lg:rounded-bl-none lg:rounded-tl-none" :disabled="$wait.is('guest-scan-email-submitted')">
        Send
      </btn>
    </form>
  </notice>
</template>

<script>
    import Btn from "../partials/Btn";
    import InputContainer from "../partials/InputContainer";
    import Notice from "../partials/Notice";
    export default {
      name: "GuestScanEmailPrompt",
      components: {Notice, InputContainer, Btn},
      props: {
        scanId: {
          required: true
        },
      },
      data() {
        return {
          form: {
            email: null
          }
        }
      },
      methods: {
        submit() {
          this.$store.dispatch(window.store.STORE_GUEST_EMAIL, { form: this.form, scanId: this.scanId })
            .then(() => this.$emit('close_success'))
            .catch((errors) => {
              _.forEach(errors, (error) => {
                this.errors.add(error);
              });
            });
        }
      }
    }
</script>
