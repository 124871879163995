import Vuex from 'vuex';
import VueStorage from './helpers/VueStorage';
import Auth from './helpers/Auth';
import { event } from 'vue-analytics';
import Utilities from "./helpers/Utilities";

const vueStorage = new VueStorage();
const auth = new Auth();

Vue.use(Vuex);

window.store = {
  /**
   * NO API CALLS
   */
  SET_USER: 'user/SET_USER',
  SET_USER_ERRORS: 'user/SET_USER_ERRORS',
  SET_USER_TOKENS: 'user/SET_USER_TOKENS',
  SET_SCANS: 'scans/SET_SCANS',
  SET_SCAN: 'scans/SET_SCAN',
  SET_INCOMING_SCAN_SOCKET_DATA: 'sockets/SET_INCOMING_SCAN_SOCKET_DATA',

  SET_LOADING: 'loading/SET_LOADING',
  SET_FRAME_LOADING: 'loading/SET_FRAME_LOADING',
  SET_FRAME_DONE_LOADING: 'loading/SET_FRAME_DONE_LOADING',

  /**
   * POST request
   */
  POST_USER_LOGIN: 'user/POST_USER_LOGIN',
  POST_USER_FORGOT_PASSWORD: 'user/POST_USER_FORGOT_PASSWORD',
  POST_USER_RESET_PASSWORD: 'user/POST_USER_RESET_PASSWORD',
  POST_CONTACT: 'contact/POST_CONTACT',

  /**
   * STORE requests
   */
  STORE_NEW_USER: 'user/STORE_NEW_USER',
  STORE_NEW_SCAN: 'scans/STORE_NEW_SCAN',
  STORE_NEW_GUEST_SCAN: 'scans/STORE_NEW_GUEST_SCAN',
  STORE_GUEST_EMAIL: 'scans/STORE_GUEST_EMAIL',

  /**
   * GET requests
   */
  GET_USER_VERIFY: 'user/GET_USER_VERIFY',
  GET_SCANS: 'scans/GET_SCANS',
  GET_SCAN: 'scans/GET_SCAN',
  GET_GUEST_SCAN: 'scans/GET_GUEST_SCAN',

  /**
   * PATCH requests
   */

  // PATCH_USER_VERIFY: 'user/PATCH_USER_VERIFY',
  /**
   * DELETE requests
   */

  // DELETE_PAGE: 'pages/DELETE_PAGE',
};

const store = new Vuex.Store({
  strict: true,
  modules: {
    sockets: {
      namespaced: true,
      state: {
        scans: {
          scan_status: '',
          tests_completed: 0,
          tests_to_run: 0,
        }
      },
      mutations: {
        SET_INCOMING_SCAN_SOCKET_DATA(state, payload) {
          state.scans = {...state.scans, [payload.scanId]: {...state.scans[payload.scanId], ...payload.data}};
        }
      }
    },
    // user: {
    //   namespaced: true,
    //   state: {
    //     user: {
    //       id: null,
    //       name: null,
    //       email: null,
    //       created_at: null,
    //       updated_at: null,
    //       deleted_at: null,
    //       email_verified_at: null,
    //       verified: null,
    //       token: null,
    //     },
    //     tokens: {},
    //     errors: {},
    //   },
    //   mutations: {
    //     SET_USER(state, payload) {
    //       state.user = payload;
    //       vueStorage.store('user', payload);
    //     },
    //
    //     SET_USER_TOKENS(state, payload) {
    //       state.tokens = payload;
    //       vueStorage.store('access', payload.access_token, payload.expires_in);
    //       vueStorage.store('access_refresh', payload.refresh_token, payload.expires_in);
    //       vueStorage.store('access_expires_in', parseInt(Date.now() / 1000, 10)
    //         + parseInt(payload.expires_in, 10), payload.expires_in);
    //     },
    //
    //     SET_USER_ERRORS(state, payload) {
    //       state.errors = payload;
    //     },
    //   },
    //   actions: {
    //     POST_USER_LOGIN(context, data) {
    //       context.commit(window.store.SET_FRAME_LOADING, null, { root: true });
    //       const url = `${window.apiUrl}/user/login`;
    //       return axios.post(url, data)
    //         .then((response) => {
    //           context.commit(window.store.SET_USER, response.data.data, { root: true });
    //           context.commit(window.store.SET_USER_TOKENS, response.data.data.access_token, { root: true });
    //           context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
    //           return response;
    //         })
    //         .catch((response) => {
    //           context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
    //           throw response.response.data.data;
    //         });
    //     },
    //
    //     POST_USER_FORGOT_PASSWORD(context, data) {
    //       context.commit(window.store.SET_FRAME_LOADING, null, { root: true });
    //       const url = `${window.apiUrl}/user/forgot`;
    //       return axios.post(url, data)
    //         .then((response) => {
    //           context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
    //           return response;
    //         })
    //         .catch((response) => {
    //           context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
    //           throw response;
    //         });
    //     },
    //
    //     POST_USER_RESET_PASSWORD(context, data) {
    //       context.commit(window.store.SET_FRAME_LOADING, null, { root: true });
    //       const url = `${window.apiUrl}/user/reset-password`;
    //       return axios.post(url, data)
    //         .then((response) => {
    //           context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
    //           return response;
    //         })
    //         .catch((response) => {
    //           context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
    //           throw response.response.data.data;
    //         });
    //     },
    //
    //     STORE_NEW_USER(context, data) {
    //       context.commit(window.store.SET_FRAME_LOADING, null, { root: true });
    //       const url = `${window.apiUrl}/user`;
    //       return axios.put(url, data)
    //         .then((response) => {
    //           context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
    //           return response;
    //         })
    //         .catch((response) => {
    //           context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
    //           throw response.response.data.data;
    //         });
    //     },
    //
    //     GET_USER_VERIFY(context, data) {
    //       context.commit(window.store.SET_FRAME_LOADING, null, { root: true });
    //       const url = `${window.apiUrl}/user/verify`;
    //       return axios.get(url, {
    //         params: data,
    //       })
    //         .then((response) => {
    //           context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
    //           return response;
    //         })
    //         .catch((response) => {
    //           context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
    //           throw response;
    //         });
    //     },
    //   },
    // },
    contact: {
      namespaced: true,
      state: {
        errors: {},
      },
      actions: {
        POST_CONTACT(context, data) {
          context.dispatch('wait/start', 'contact-form-submit', { root: true });
          event('contact-form', 'submitted');

          return axios.post(`${window.apiUrl}/contact`, data)
            .then((response) => {
              return response;
            })
            .catch((response) => {
              throw Utilities.transformApiErrors(response.response.data.data);
            })
            .finally(() => {
              context.dispatch('wait/end', 'contact-form-submit', { root: true });
            });
        },
      },
    },
    scans: {
      namespaced: true,
      state: {
        scans: [
          {
            id: null,
            user_id: null,
            name: null,
            status: null,
            score: null,
            params: {},
            created_at: null,
            updated_at: null,
            deleted_at: null,
            groups: {},
          },
        ],
        meta: {
          pagination: {
            total: null,
            count: null,
            per_page: null,
            current_page: null,
            total_pages: null,
            links: {},
          },
        },
        scan: {
          created_at: null,
          updated_at: null,
          id: null,
          name: null,
          params: {},
          score: 0,
          status: 'pending',
          token: null,
          results: {
            high: {
              meta: {},
              data: {}
            },
            medium: {
              meta: {},
              data: {}
            },
            low: {
              meta: {},
              data: {}
            },
            none: {
              meta: {},
              data: {}
            },
          }
        },
      },
      mutations: {
        SET_SCANS(state, payload) {
          state.scans = payload.data;
          state.meta = payload.meta;
        },

        SET_SCAN(state, payload) {
          state.scan = {...state.scan, ...payload};
        },
      },
      actions: {
        STORE_GUEST_EMAIL(context, data) {
          context.dispatch('wait/start', 'guest-scan-email-submitted', { root: true });
          event('guest-scan', 'email-submitted', 'email', data.form.email);

          return axios.post(`${window.apiUrl}/guest-scan/${data.scanId}/send-results-email`, data.form)
            .then(() => {
              return true;
            })
            .catch((response) => {
              throw Utilities.transformApiErrors(response.response.data.data);
            })
            .finally(() => {
              context.dispatch('wait/end', 'guest-scan-email-submitted', { root: true });
            });
        },
        STORE_NEW_SCAN(context, data) {
          context.commit(window.store.SET_FRAME_LOADING, null, { root: true });
          const url = `${window.apiUrl}/scan`;
          const newData = _.defaultsDeep({}, data);
          newData.status = 'pending';
          newData.score = 0;
          newData.params = {};
          Object.keys(data.params).forEach((item) => {
            newData.params[data.params[item].name] = data.params[item].value;
          });

          return axios.put(url, newData)
            .then((response) => {
              context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
              return response;
            })
            .catch((response) => {
              if (response.response.status === 401) {
                auth.removeToken();
              }

              context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
              throw response;
            });
        },

        STORE_NEW_GUEST_SCAN(context, data) {
          const url = `${window.apiUrl}/guest-scan`;
          event('guest-scan', 'started', 'url', data.url);

          return axios.put(url, data)
            .then((response) => {
              context.commit(window.store.SET_SCAN, {...response.data.data, results: {}}, { root: true });
              return response;
            })
            .catch((response) => {
              if (response.response.status === 401) {
                auth.removeToken();
              }

              throw Utilities.transformApiErrors(response.response.data.data);
            });
        },

        GET_SCANS(context, data) {
          context.commit(window.store.SET_FRAME_LOADING, null, { root: true });
          const url = `${window.apiUrl}/scans`;
          return axios.get(url, { params: data })
            .then((response) => {
              context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
              context.commit(window.store.SET_SCANS, response.data, { root: true });
              return response;
            })
            .catch((response) => {
              if (response.response.status === 401) {
                auth.removeToken();
              }

              context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
              throw response;
            });
        },

        GET_SCAN(context, data) {
          context.commit(window.store.SET_FRAME_LOADING, null, { root: true });

          const url = `${window.apiUrl}/scan/${data}`;

          return axios.get(url, { params: { includes: 'groups.items' } })
            .then((response) => {
              context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
              context.commit(window.store.SET_SCAN, response.data, { root: true });
              return response;
            })
            .catch((response) => {
              if (response.response.status === 401) {
                auth.removeToken();
              }

              context.commit(window.store.SET_FRAME_DONE_LOADING, null, { root: true });
              throw response;
            });
        },

        GET_GUEST_SCAN(context, data) {
          const url = `${window.apiUrl}/guest-scan/${data}/items-grouped`;

          return axios.get(url)
            .then((response) => {
              context.commit(window.store.SET_SCAN, { results: response.data.data }, { root: true });
              return response;
            })
            .catch((response) => {
              if (response.response.status === 401) {
                auth.removeToken();
              }

              throw response;
            })
        },
      },
      getters: {
        scanHasResults: (state) => {
          return !_.isUndefined(_.find(state.scan.results, (severity) => {
            return severity.data.length;
          }));
        }
      }
    },
    // loading: {
    //   namespaced: true,
    //   state: {
    //     loading: {
    //       isLoading: false,
    //       isFullPage: true,
    //       canCancel: false,
    //       onCancel: () => {
    //       },
    //
    //       loader: 'bars',
    //       timeout: 6000,
    //     },
    //   },
    //   mutations: {
    //     SET_LOADING(state, payload) {
    //       state.loading = payload;
    //     },
    //
    //     SET_FRAME_LOADING(state) {
    //       const st = _.defaultsDeep({}, state.loading);
    //       st.isLoading = true;
    //       state.loading = st;
    //     },
    //
    //     SET_FRAME_DONE_LOADING(state) {
    //       const st = _.defaultsDeep({}, state.loading);
    //       st.isLoading = false;
    //       state.loading = st;
    //     },
    //   },
    // },
  },
});

export default store;
