<template>
  <section class="pt-25vh md:pt-30vh md:mb-10 pt-32 min-h-screen">
    <div class="container">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroPanel',
};
</script>
