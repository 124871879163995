import App from './App.vue';
import router from './router';
import store from './store';
import './assets/app.scss';
import VueWait from 'vue-wait';
import PortalVue from 'portal-vue';
import VueAnalytics from 'vue-analytics';
import VeeValidate from 'vee-validate';
require('./constants');
require('./directives');

Vue.config.productionTip = false;
Vue.use(PortalVue);
Vue.use(VueWait);
Vue.use(VeeValidate, {
  events: ''
});
Vue.use(VueAnalytics, {
  id: window.googleAnalyticsKey,
  router,
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production'
  }
});

axios.interceptors.request.use((config) => {
  const newConfig = config;

  newConfig.headers.common = {
    'X-CSRF-TOKEN': window.csrfToken,
    'X-Requested-With': 'XMLHttpRequest',
  };

  if (vueStorage.fetch('access') != null) {
    newConfig.headers.common.Authorization = `Bearer ${vueStorage.fetch('access')}`;
  }

  return newConfig;
});

new Vue({
  router,
  store,
  wait: new VueWait({
    useVuex: true,
    registerDirective: true,
  }),
  render: h => h(App),
}).$mount('#app');
