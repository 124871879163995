<template>
  <nav ref="nav" class="fixed z-10 pin-x pin-t">
    <div class="container py-6 md:py-8">
      <div class="flex items-center">
        <div class="flex flex-grow items-center">
          <router-link :to="{name: 'home'}" class="flex items-center no-underline">
            <img src="../../assets/logo-white.svg" class="logo logo-white"/>
            <img src="../../assets/logo-blue.svg" class="logo logo-blue"/>
            <h1 class="main-heading text-base font-semibold tracking-wide leading-none">
              ExploitSentry<br />
              <span class="text-xs uppercase opacity-50">Beta</span>
            </h1>
          </router-link>
        </div>
<!--        <div class="hidden md:block">-->
<!--          <template v-if="!isUser">-->
<!--            <router-link class="link" :to="{name: 'login'}">Sign in</router-link>-->
<!--            <btn class="btn-white" :to="{name: 'register'}">Run Advanced Scan</btn>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            <router-link class="link" @click="logout">Sign out</router-link>-->
<!--            <btn class="btn-white" :to="{name:'dashboard'}">Dashboard</btn>-->
<!--          </template>-->
<!--        </div>-->
      </div>
    </div>
  </nav>
</template>
<script>
import Auth from '../../helpers/Auth';
import Btn from '../partials/Btn.vue';
import Modal from '../partials/Modal';

export default {
  components: { Modal, Btn },
  computed: {
    isUser() {
      return this.$store.state.user.user.id != null || this.auth.isValidUser();
    },
  },
  mounted() {
    this.auth = new Auth();
    /**
     * TODO: Make a helper class for Midnight.js so rebooting it on
     * router change etc won't be an issue
     */
    require('@/helpers/legacy-libs/midnight.jquery.js');
    $(this.$refs['nav']).midnight();
  },
  methods: {
    logout() {
      this.auth.removeToken();
      this.$router.push({ name: 'home' });
    },
  },
};
</script>
<style scoped lang="scss">
  .midnightHeader {
    .logo {
      @apply w-8 mr-4 hidden;
    }

    &.default {
      .main-heading, .link {
        @apply text-white;
      }

      .logo-white {
        @apply inline-block;
      }
    }

    &.blue {
      .main-heading, .link {
        @apply text-blue-darker;
      }

      .logo-blue {
        @apply inline-block;
      }
    }
  }

  .main-heading, .link {
    @apply mr-10;
  }

  .link {
    @apply inline-block;
    @apply text-sm no-underline;
  }
</style>
