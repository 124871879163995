import Echo from 'laravel-echo/dist/echo';
import store from '../store';
import {event} from "vue-analytics";

export default class ScanSocketManager
{
  channelKey = 'exploitsentry_databasescan.';
  _scanId = null;
  _scanToken = null;

  constructor(scanId) {
    this._scanId = scanId;
    this._scanToken = store.state.scans.scan.token;

    this.commitSocketDataToStore({
      scan_status: 'Preparing scan...',
      tests_completed: 0,
      tests_to_run: 0,
    });

    /**
     * If there is no scanToken in the store that means we're
     * fetching a scan that's already finished so don't bother
     * with echo, just update the scan results in the store
     */
    if (_.isNull(this._scanToken)) {
      store.dispatch(window.store.GET_GUEST_SCAN, this._scanId, { root: true });
      return;
    }

    if (_.isUndefined(window.io)) {
      this.initSocketIo();
    }

    if (_.isUndefined(window.Echo)) {
      this.initEcho();
    }

    this.connectAndListen();
  }

  initEcho() {
    if (_.isUndefined(window.io)) {
      this.initSocketIo();
    }

    window.Echo = new Echo({
      broadcaster: 'socket.io',
      host: window.echoServer,
      auth: {
        headers: {
          Authorization: `Bearer ${window.vueStorage.fetch('access')}`,
        },
      },
      transports: ['websocket', 'polling', 'flashsocket'],
      disableStats: true,
      encrypted: true
    });
  }

  initSocketIo() {
    window.io = require('socket.io-client');
  }

  get testsCompleted() {
    return store.state.sockets['scans'][this._scanId]['tests_completed'];
  }

  get testsToRun() {
    return store.state.sockets['scans'][this._scanId]['tests_to_run'];
  }

  connectAndListen() {
    store.dispatch('wait/start', 'guest-scan', { root: true });

    window.Echo.channel(this.channelKey + this._scanToken)
      .listen('.scan.started', (e) => {
        this.commitSocketDataToStore({
          scan_status: 'Starting scan...',
          tests_completed: 1,
          tests_to_run: e.tests_to_run + 3
        });
      })
      .listen('.scan.finished', (e) => {
        this.commitSocketDataToStore({
          scan_status: 'Processing results...',
          tests_completed: this.testsCompleted + 1,
        });

        store.dispatch(window.store.GET_GUEST_SCAN, this._scanId, { root: true })
          .then(() => {
            this.commitSocketDataToStore({
              scan_status: 'Scan finished',
              tests_completed: this.testsToRun,
            });

            this.disconnect();
            store.dispatch('wait/end', 'guest-scan', { root: true });
            event('guest-scan', 'finished', 'url', store.state.scans.scan.params.url);
          });
      })
      .listen('.scan.group.started', (e) => {
        this.commitSocketDataToStore({
          scan_status: e.label + '...',
          tests_completed: this.testsCompleted + 1,
        });
      });
  }

  commitSocketDataToStore(payload) {
    store.commit(window.store.SET_INCOMING_SCAN_SOCKET_DATA, {
      ...{ scanId: this._scanId },
      data: payload
    });
  }

  disconnect() {
    if (this._scanToken != null) {
      window.Echo.leave(this.channelKey + this._scanToken);
    }
  }
}
