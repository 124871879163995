import VueStorage from './VueStorage';

export default class Auth
{
  constructor() {
    this.storage = new VueStorage();
  }

  isValidUser() {
    return this.storage.fetch('access') != null
      && this.storage.fetch('access_expires_in') != null
      && this.storage.fetch('access_expires_in') > parseInt(Date.now() / 1000, 10);
  }

  removeToken() {
    this.storage.purge('access');
    this.storage.purge('access_expires_in');
    this.storage.purge('access_refresh');
  }
}
