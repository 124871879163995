export default class Scroll
{
  static goTo(element, centrePage = false, speed = 500) {
    if (element instanceof jQuery == false) {
      element = $(element);
    }

    return new Promise((resolve) => {
      let amountToScroll = element.offset().top;

      if (centrePage == true) {
        amountToScroll -= (window.innerHeight / 2);
      }

      $('html, body').animate({
        scrollTop: amountToScroll
      }, speed, resolve);
    })
  }
}
