<template>
  <div>
    <div class="relative h-full w-full">
      <div style="background-image: url(/img/map.svg); opacity: .25;" class="absolute h-full w-full bg-left bg-cover"></div>
      <pulse class="absolute" style="top: 50%; left: 50%;" />
      <pulse class="absolute" style="top: 25%; left: 50%; animation-delay: 4s" />
      <pulse class="absolute" style="top: 30%; left: 45%; animation-delay: 5.5s" />
      <pulse class="absolute" style="top: 30%; left: 20%; animation-delay: .5s" />
    </div>
  </div>
</template>
<script>
  import Pulse from "./Pulse";
  export default {
    name: "MapSvg",
    components: {Pulse}
  }
</script>

<style scoped>

</style>
