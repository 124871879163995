<template>
  <div class="tooltip-container">
    <slot></slot>
    <div class="tooltip trans-all-15ms" :class="['tooltip-' + position]">{{ text }}</div>
  </div>
</template>

<script>
  export default {
    name: 'Tooltip',
    props: {
      text: {},
      position: {
        default: 'left'
      }
    }
  };
</script>

<style scoped lang="scss">
  .tooltip-container {
    @apply relative;

    .tooltip {
      @apply absolute opacity-0;
      @apply bg-black text-white rounded text-sm;
      @apply px-4 py-2 pointer-events-none;

      min-width: 200px;

      &:before {
        @apply bg-black;
        @apply absolute;
        content: '';
        width: 10px;
        height: 10px;
      }

      &.tooltip-left {
        left: -10px;
        top: 50%;
        transform: translate(-100%, -50%);

        &:before {
          @apply pin-r;
          top: 50%;
          transform: translate(50%, -50%) rotate(45deg);
        }
      }

      &.tooltip-right {
        right: -10px;
        top: 50%;
        transform: translate(100%, -50%);

        &:before {
          @apply pin-l;
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }

    &:hover {
      .tooltip {
        @apply opacity-100 pointer-events-auto;
      }
    }
  }
</style>
