<template>
  <div class="flex bg-white rounded shadow-hard">
    <icon v-if="icon" :name="icon" class="pl-3 inline-flex" />
    <slot />
  </div>
</template>

<script>
  import Icon from './Icon';
  export default {
    name: 'InputContainer',
    components: { Icon },
    props: ['icon']
  };
</script>
