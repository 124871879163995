<template>
  <div :class="{ 'inline-flex': !hideOnMobile, 'hidden': hideOnMobile }" class="rounded-full items-center justify-center">
    <img :src="'/img/icons/' + name + '.svg'" />
  </div>
</template>

<script>
  export default {
    name: 'Icon',
    props: {
      name: {},
      hideOnMobile: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
