<template>
  <div>
    <div class="md:flex items-center px-3 py-8 text-left border-l-3 my-1" :class="['border-scan-result-' + severity]">
      <div v-if="severity != 'pass'" class="md:pl-8 md:pr-6">
        <span class="text-4xl font-semibold" :class="['text-scan-result-' + severity]">{{ item.rating }}</span>
        <span class="font-bold text-grey pl-1">%</span>
      </div>
      <div :class="{'py-4': severity != 'pass'}" class="md:pl-6 md:pr-16 md:py-0 flex-grow">
        <h2 class="small-heading" :class="['text-scan-result-' + severity]">{{ item.title }}</h2>
        <p class="text-blue-dark text-sm leading-normal break-words">{{ item.message + '. ' + item.solution }}</p>
      </div>
<!--      <div class="small-heading md:text-right pr-3" :class="['text-' + severity]">Details</div>-->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ResultsAccordionBody',
    props: {
      item: {
        required: true
      },
      severity: {
        default: 'high'
      }
    }
  };
</script>

<style scoped lang="scss">
  .small-heading {
    @apply uppercase font-bold tracking-wide opacity-75 text-xs;
  }
</style>
