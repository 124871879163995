import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/pages/Home.vue';
import Auth from './helpers/Auth';
import Header from './views/globals/Header.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        guest: true,
      },
      components: {
        default: Home,
        header: Header,
      },
    },
    {
      path: '/login',
      name: 'login',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: {
        guest: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "login" */ './views/legacy/Login.vue'),
        header: Header,
      },
    },
    {
      path: '/register',
      name: 'register',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: {
        guest: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "register" */ './views/legacy/Register.vue'),
        header: Header,
      },
    },
    {
      path: '/verify',
      name: 'verify',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: {
        guest: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "verify" */ './views/legacy/Verify.vue'),
        header: Header,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: {
        guest: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "forgot-password" */ './views/legacy/ForgotPassword.vue'),
        header: Header,
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: {
        guest: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "reset-password" */ './views/legacy/ResetPassword.vue'),
        header: Header,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "dashboard" */ './views/legacy/Dashboard.vue'),
        header: Header,
      },
    },
    {
      path: '/scans',
      name: 'scans',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "scans" */ './views/legacy/Scans.vue'),
        header: Header,
      },
    },
    {
      path: '/new-scan',
      name: 'new-scan',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "new-scan" */ './views/legacy/NewScan.vue'),
        header: Header,
      },
    },
    {
      path: '/scan/:id',
      name: 'scan',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "scan" */ './views/scans/Scan.vue'),
        header: Header,
      },
    },
    {
      path: '/guest-scan/:id',
      name: 'guest-scan',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      meta: {
        guest: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "guest-" */ './views/legacy/GuestScan.vue'),
        header: Header,
      },
    },
  ],
});

const auth = new Auth();

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.isValidUser()) {
      next({
        name: 'login',
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guest) && auth.isValidUser()) {
    next({
      name: 'dashboard',
    });
  } else {
    next();
  }
});

export default router;
