export default {
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$validator.validate().then((result) => {
          result == true ? resolve() : reject();
        });
      });
    },
  }
};

/**
 * Make window available everywhere
 * this is auto imported to every component
 */
Vue.mixin({
  computed: {
    window() {
      return window;
    }
  }
});
