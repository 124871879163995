<template>
  <div class="md:p-12 bg-gradient-grey shadow rounded">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'Card'
  };
</script>
