import Tailwind from "../tailwind";

Vue.directive('focus', {
  inserted: (el) => el.focus()
});

Vue.directive('add-class-on-scroll', {
  inserted: (el, binding) => {
    if (window.innerWidth < parseInt(Tailwind.screens.md)) {
      return;
    }

    let debounceWait = 100;

    let f = _.debounce(() => {
      let bounding = el.getBoundingClientRect();

      if (bounding.top >= 0 && bounding.left >= 0 &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight))
      {
        _.each(binding.value.split(' '), (_class) => el.classList.add(_class));
        window.removeEventListener('scroll', f);
      }
    }, debounceWait);

    window.addEventListener('scroll', f);

    if (window.isPrendering == false) {
      f();
    }
  }
});
