<template>
  <div class="circle-bg"></div>
</template>

<script>
  export default {
    name: 'CircleBg'
  };
</script>

<style lang="scss">
  .circle-bg {
    @apply absolute bg-white rounded-full;

    width: 120px;
    height: 120px;

    &:nth-child(1) {
      opacity: .1;
      bottom: auto;
      left: -4%;
    }

    &:nth-child(2) {
      opacity: .1;
      top: 10%;
      right: 4%;
    }

    &:nth-child(3) {
      opacity: .3;
      top: 50%;
      right: 5%;
    }

    &:nth-child(4) {
      opacity: .15;
      top: 55%;
      right: 7%;
    }

    &:nth-child(5) {
      opacity: .05;
      top: 38%;
      right: auto;
    }

    &:nth-child(6) {
      width: 200px;
      height: 200px;

      opacity: .15;
      top: 44%;
      left: 10%;
    }

    &:nth-child(7) {
      opacity: .04;
      bottom: 50%;
      right: 36%;
    }

    &:nth-child(8) {
      opacity: .2;
      bottom: 12%;
      right: 1%;
    }

    &:nth-child(9) {
      opacity: .1;
      bottom: 1%;
      right: 2%;
    }
  }
</style>
