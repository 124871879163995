<template>
  <div>
    <progress-bar :step="socket.scan_status" :progress="progressPercent" colour="green" class="mt-8" />

    <slide-up-down :active="showEmailPrompt">
      <guest-scan-email-prompt ref="email_prompt" :scan-id="id" @close="showEmailPrompt = false" class="mt-8" />
    </slide-up-down>

    <slide-up-down :active="showEmailPrompt">
      <notice v-if="showEmailPromptSuccess" @close="showEmailPromptSuccess = false" class="mt-8" :can-close="true" type="success">
        Great, we'll email you a copy of your test results!
      </notice>
    </slide-up-down>

    <results-accordion v-if="scanHasResults" :results="scan.results"></results-accordion>
  </div>
</template>
<script>
import ResultsAccordion from './ResultsAccordion';
import ProgressBar from '../partials/ProgressBar';
import ScanSocketManager from '../../helpers/ScanSocketManager';
import GuestScanEmailPrompt from "./GuestScanEmailPrompt";
import Notice from "../partials/Notice";
import SlideUpDown from 'vue-slide-up-down';

export default {
  components: { Notice, GuestScanEmailPrompt, ProgressBar, ResultsAccordion, SlideUpDown },
  props: ['id'],
  data() {
    return {
      showEmailPrompt: true,
      showEmailPromptSuccess: false,
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(newId, oldId) {
        if (newId !== oldId) {
          if (!_.isUndefined(oldId)) {
            this.scanSocket.disconnect();
          }

          this.scanSocket = new ScanSocketManager(newId);
        }
      }
    }
  },
  mounted() {
    this.$refs['email_prompt'].$on('close_success', () => {
      this.showEmailPromptSuccess = true;
      this.showEmailPrompt = false;
    });
  },
  beforeDestroy() {
    this.scanSocket.disconnect();
  },
  computed: {
    socket() {
      return this.$store.state.sockets.scans[this.id];
    },
    scan() {
      return this.$store.state.scans.scan;
    },
    scanHasResults() {
      return this.$store.getters['scans/scanHasResults'];
    },
    progressPercent() {
      return this.socket.tests_completed == 0 ? 0 : parseInt((this.socket.tests_completed / this.socket.tests_to_run) * 100);
    }
  },
};
</script>
