export default class VueStorage
{
  constructor() {
    this.maxNumberOfStorageItems = 20;
    this.inUseKeyName = 'current_in_use_key';
    this.keysNeverToBePopped = ['access', 'access_refresh', 'access_expires_in'];
    this.isLocalStorageEnabled = null;
    this.bag = null;
  }

  isLocalStorage() {
    if (this.isLocalStorageEnabled != null) {
      return this.isLocalStorageEnabled;
    }

    const test = 'test';
    this.isLocalStorageEnabled = false;

    if (Object.getPrototypeOf(localStorage) === Storage.prototype) {
      try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        this.isLocalStorageEnabled = true;
        this.bag = localStorage;
      } catch (e) {
        console.log(e);
      }
    } else if (Object.getPrototypeOf(localStorage) === Object.getPrototypeOf(sessionStorage)) {
      try {
        sessionStorage.setItem(test, test);
        sessionStorage.removeItem(test);
        this.isLocalStorageEnabled = true;
        this.bag = sessionStorage;
      } catch (e) {
        console.log(e);
      }
    } else {
      this.isLocalStorageEnabled = false;
    }

    return this.isLocalStorageEnabled;
  }

  cleanCurrentInUseKey() {
    const key = this.bag.getItem(this.inUseKeyName);

    if (key !== null) {
      this.purge(key);
      this.bag.removeItem(this.inUseKeyName);
    }
  }

  popOldestEntry() {
    if (!this.isLocalStorage()) {
      return;
    }

    let i = 0;
    let oldestKey = null;

    while (this.bag.key(i) != null) {
      const key = this.bag.key(i);
      const item = this.bag.getItem(key);

      if (this.keysNeverToBePopped.indexOf(key) === -1) {
        oldestKey = oldestKey == null || item.created < oldestKey ? key : oldestKey;
      }

      i += 1;
    }

    this.purge(oldestKey);
  }

  store(key, data, expires = 3600) {
    if (this.isLocalStorage() === true) {
      if ((this.bag.length + 1) >= this.maxNumberOfStorageItems) {
        this.popOldestEntry();
      }

      const metaData = {
        object: data,
        created: new Date() / 1000,
        expires,
      };

      this.bag.setItem(key, JSON.stringify(metaData));
      this.bag.setItem(this.inUseKeyName, key);
    }
  }

  fetch(key) {
    const data = this.fetchIfNotExpired(key);

    if (data !== false) {
      this.bag.setItem(this.inUseKeyName, key);
    }

    return data;
  }

  purge(key) {
    if (this.isLocalStorage() === true) {
      this.bag.removeItem(key);
    }
  }

  fetchIfNotExpired(key) {
    if (this.isLocalStorage() === true) {
      let item = this.bag.getItem(key);

      if (item !== null) {
        item = JSON.parse(item);
        const now = new Date() / 1000;

        if (item.created < now - item.expires) {
          this.purge(key);
          return null;
        }

        return item.object;
      }
    }

    return null;
  }
}
