<template>
  <div id="app">
    <portal-target name="modals" />
    <router-view name="header" />
    <router-view class="relative" />
    <bottom />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Bottom from './views/globals/Bottom';

export default {
  components: { Bottom, Loading },
};
</script>
