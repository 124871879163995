<template>
    <div v-if="messages.length >  0 || hasSlot" :class="{ 'border-red': type == 'error', 'border-blue-light': type == 'info', 'border-green': type == 'success' }"
         class="relative py-2 pl-4 pr-10 mb-4 border-l-4 rounded-tr font-semibold rounded-br bg-white shadow-hard text-sm text-blue-dark text-left">
      <div v-for="message in messages">
        {{ message }}
      </div>
      <slot />
      <icon v-if="canClose" @click.native="$emit('close')" class="absolute pin-r pin-t pr-2 py-2 cursor-pointer" name="close" />
    </div>
</template>

<script>
  import Icon from "./Icon";
  export default {
    name: 'Notice',
    components: {Icon},
    props: {
      messages: {
        type: Array,
        default: () => {
          return [];
        }
      },
      type: {
        default: 'error'
      },
      canClose: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      hasSlot() {
        return !_.isEmpty(this.$slots);
      }
    }
  };
</script>

