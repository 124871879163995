<template>
  <div>
    <div class="flex mb-2 items-center">
      <div class="flex-grow text-blue-dark text-left uppercase text-xs font-bold">{{ step }}</div>
      <div class="text-blue-dark font-bold text-sm">{{ progress }}%</div>
    </div>
    <div class="relative rounded bg-grey-dark h-2 shadow-inset">
      <div class="trans-all-50ms absolute pin-y pin-l rounded" :class="['bg-' + colour]" :style="{ 'width': progress + '%' }"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProgressBar',
    props: ['step', 'progress', 'colour']
  };
</script>


