<template>
  <div class="skewed-separator absolute pin-x" :class="{ 'pin-b': position == 'bottom', 'pin-t': position == 'top' }" :style="{ color: _colour }">
    <svg :style="{ 'box-shadow': 'inset 0 -2px 0 0 ' + _colour }" class="absolute pin-b" x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon class="text-inherit fill-current" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</template>

<script>
import Tailwind from "@/../tailwind";

export default {
  name: 'SkewedSeparator',
  props: {
    position: {
      default: 'bottom'
    },
    colour: {}
  },
  computed: {
    _colour() {
      return Tailwind.colors[this.colour];
    }
  }
};
</script>
