<template>
  <div class="relative pb-10 pt-10 md:pt-20">
    <skewed-separator class="text-grey-light" />
    <div class="container relative z-10">
      <div class=" pt-8 border-t border-grey-dark flex">
        <div class="flex-grow text-sm">
          &copy; {{ year }} <a href="#" class="text-blue">ExploitSentry</a>
        </div>
<!--        <div>-->
<!--          <a href="#" class="footer-link">Link</a>-->
<!--          <a href="#" class="footer-link">Link</a>-->
<!--          <a href="#" class="footer-link">Link</a>-->
<!--          <a href="#" class="footer-link">Link</a>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
  import SkewedSeparator from '../partials/SkewedSeparator';
  export default {
    name: 'Bottom',
    components: { SkewedSeparator },
    computed: {
      year() {
        return new Date().getFullYear();
      }
    }
  };
</script>

<style scoped>
  .footer-link {
    @apply text-blue-dark pl-4 no-underline text-sm;
  }
</style>
