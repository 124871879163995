<template>
  <section class="layout">
    <div class="container md:flex items-center" :class="containerClass">
      <div class="w-full md:w-1/2" :class="leftClass">
        <slot name="left" />
      </div>
      <div class="w-full md:w-1/2 mt-10 md:mt-0" :class="rightClass">
        <slot name="right" />
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Checkerboard',
    props: ['left-class', 'right-class', 'container-class']
  };
</script>
