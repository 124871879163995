<template>
  <div class="home">
    <div class="bg-gradient-blue h-screen absolute pin overflow-hidden">
      <map-svg class="animate-fade-in absolute pin h-full w-full hidden md:block" style="left: 35%;" />
      <skewed-separator colour="white" />
    </div>
    <hero-panel class="text-white relative z-10">
      <div class="mb-16 md:mb-32">
        <h1 class="text-4xl font-semibold leading-normal animate-in" v-add-class-on-scroll="window.animateInClass">
          <span class="opacity-75">Find </span>your vulnerabilities<br />
          <span class="opacity-75">before they do</span>
        </h1>
        <p class="text-xl font-light leading-normal my-2 animate-in" v-add-class-on-scroll="window.animateInClass">
          <span class="opacity-75">Everyday thousands of websites are hacked<br />
            Make sure you're not one of them</span>
        </p>
      </div>
      <guest-scan-form :class="{'animate-bounce': guestFormPulse == true}"
                       ref="guest-scan-anchor"
                       data-midnight="blue" />
    </hero-panel>
    <checkerboard left-class="md:pr-10" data-midnight="blue">
      <template v-slot:left>
        <h2 class="heading1 animate-in" v-add-class-on-scroll="window.animateInClass">What can I do?</h2>
        <p class="animate-in" v-add-class-on-scroll="window.animateInClass">
          Keeping up to date with your website's security can be complex and expensive.
          Our free scan provides an instant and easy to read overview of your security flaws; no sign-up required.
        </p>
        <p class="animate-in" v-add-class-on-scroll="window.animateInClass"></p>
        <div class="mt-4 mb-6 animate-in" v-add-class-on-scroll="window.animateInClass">
          <list-item>
            <icon name="magnifying-glass" class="inline-flex w-8 h-8 bg-blue" />
            <h3 class="ml-4 font-normal text-base">Ideal for a broad overview</h3>
          </list-item>
          <list-item>
            <icon name="bar-chart" class="inline-flex w-8 h-8 bg-blue" />
            <h3 class="ml-4 font-normal text-base">Straightforward results</h3>
          </list-item>
          <list-item>
            <icon name="run" class="inline-flex w-8 h-8 bg-blue" />
            <h3 class="ml-4 font-normal text-base">Simple, fast and free</h3>
          </list-item>
        </div>
        <div class="animate-in" v-add-class-on-scroll="window.animateInClass">
          <btn @clicked="goToFreeScan()" class="btn-orange">Run Free Scan Now</btn>
<!--          <btn class="btn-orange-tertiary lg:ml-4">What is checked?</btn>-->
        </div>
      </template>
      <template v-slot:right>
        <div class="relative">
          <img src="../../assets/results-1.png"
               class="animate-in mx-auto block shadow border-4 border-white"
               v-add-class-on-scroll="window.animateInClass" />
          <img src="../../assets/results-2.png"
               class="animate-in mx-auto block shadow border-4 border-white xl:absolute mt-8 xl:mt-0 pin-t-50 pin-l-50"
               v-add-class-on-scroll="window.animateInClass" />
        </div>
      </template>
    </checkerboard>
<!--    <checkerboard class="pb-44" left-class="md:pl-10" container-class="md:flex-row-reverse">-->
<!--      <template v-slot:left>-->
<!--        <h2 class="heading1">Security is critical</h2>-->
<!--        <p class="text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce feugiat diam in nisi dictum suscipit.-->
<!--          Integer venenatis ipsum nisi, ut venenatis urna mattis eu.</p>-->
<!--        <div class="mt-4 mb-10">-->
<!--          <list-item>-->
<!--            <icon name="magnifying-glass" class="w-8 h-8 bg-blue" />-->
<!--            <h3 class="ml-4 font-normal text-base">Excellent for a quick overview</h3>-->
<!--          </list-item>-->
<!--          <list-item>-->
<!--            <icon name="bar-chart" class="w-8 h-8 bg-blue" />-->
<!--            <h3 class="ml-4 font-normal text-base">Easy to understand results</h3>-->
<!--          </list-item>-->
<!--          <list-item>-->
<!--            <icon name="run" class="w-8 h-8 bg-blue" />-->
<!--            <h3 class="ml-4 font-normal text-base">Free and fast</h3>-->
<!--          </list-item>-->
<!--        </div>-->
<!--        <div>-->
<!--          <btn class="btn-orange my-2">Run Advanced Scan Now</btn>-->
<!--          <btn class="btn-orange-tertiary lg:ml-4">What is checked?</btn>-->
<!--        </div>-->
<!--      </template>-->
<!--      <template v-slot:right>-->
<!--        <img src="https://placehold.it/450x300" class="mx-auto block" />-->
<!--      </template>-->
<!--    </checkerboard>-->
    <section class="layout bg-blue-darker pt-16 pb-52">
      <skewed-separator colour="blue-darker" position="top" />
      <div class="container">
        <h1 class="text-4xl font-medium leading-normal text-white text-center">Get in touch</h1>
      </div>
      <skewed-separator colour="white" />
    </section>
    <div class="container overlap">
      <div class="flex justify-center">
        <card class="w-full md:w-2/3 p-4">
          <notice v-if="errors != false" type="error" :messages="errors.all()" />
          <notice v-if="formSuccess == true" type="success">
            Thank you, your message has been sent!
          </notice>
          <form @submit.prevent="submit">
            <input-container class="mb-4" icon="tag">
              <input name="name" type="text" v-validate required placeholder="Your Name" v-model="form.name" />
            </input-container>
            <input-container class="mb-4" icon="letter">
              <input name="email" type="email" v-validate required placeholder="Your Email" v-model="form.email" />
            </input-container>
            <input-container class="mb-4">
              <textarea name="message" v-validate required class="w-full h-24" placeholder="Your Message" v-model="form.message"></textarea>
            </input-container>
            <input type="hidden" name="token" v-model="form.token">
            <google-recaptcha :sitekey="window.googleCaptchaSiteKey" action="homepage" @google_captcha_token="updateGoogleToken" />
            <btn class="btn-blue-darker w-full" :disabled="$wait.is('contact-form-submit')">Send</btn>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import HeroPanel from '../partials/HeroPanel.vue';
import GuestScanForm from '../scans/GuestScanForm.vue';
import SkewedSeparator from '../partials/SkewedSeparator';
import Btn from '../partials/Btn';
import Icon from '../partials/Icon';
import ListItem from '../partials/ListItem';
import Checkerboard from '../partials/Checkerboard';
import CircleBg from '../partials/CircleBg';
import Card from '../partials/Card';
import InputContainer from '../partials/InputContainer';
import Tooltip from '../partials/Tooltip';
import ScanResult from '../scans/ScanResult';
import Notice from '../partials/Notice';
import GoogleRecaptcha from 'vue2-recaptchav3';
import Scroll from '../../helpers/Scroll';
import Utilities from "../../helpers/Utilities";
import Validate from '../../mixins';
import MapSvg from "../partials/MapSvg";

export default {
  components: {MapSvg, Notice, ScanResult, Tooltip, InputContainer, Card, CircleBg, Checkerboard, ListItem, Icon, SkewedSeparator, GuestScanForm, HeroPanel, Btn, GoogleRecaptcha },
  name: 'Home',
  mixins: [Validate],
  data() {
    return {
      guestFormPulse: false,
      formSuccess: false,
      form: {
        token: null,
        name: null,
        email: null,
        message: null,
      }
    }
  },
  methods: {
    goToFreeScan() {
      Scroll.goTo(this.$refs['guest-scan-anchor'].$el, true)
        .then(() => this.guestFormPulse = true)
        .then(() => Utilities.wait(1250))
        .then(() => this.guestFormPulse = false);
    },
    updateGoogleToken(payload) {
      this.form.token = payload.response_token;
    },
    submit() {
      this.validate()
        .then(() => {
          return this.$store.dispatch(window.store.POST_CONTACT, this.form);
        })
        .then(() => this.formSuccess = true).catch((errors) => {
          _.forEach(errors, (error) => {
            this.errors.add(error);
          });
        });
    }
  }
};
</script>

