<template>
  <div class="bg-white rounded mt-8">
    <template v-for="(accordion, key) in results">
      <results-accordion-heading
        :count="accordion.data.length"
        :severity="key"
        :tip="accordion.meta.help_text"
        :is-active="activeAccordion == key"
        @click.native="activeAccordion = activeAccordion == key ? null : key">
          {{ key }}
      </results-accordion-heading>

      <slide-up-down :active="activeAccordion == key">
        <results-accordion-body
          v-for="(item, j) in accordion.data"
          :key="key + '-' + j"
          :severity="key"
          :item="item"
          :class="{'bg-grey-lightest': j % 2 === 0}">
        </results-accordion-body>
      </slide-up-down>
    </template>
  </div>
</template>

<script>
  import ResultsAccordionHeading from './ResultsAccordionHeading';
  import ResultsAccordionBody from './ResultsAccordionBody';
  import SlideUpDown from 'vue-slide-up-down';

  export default {
    name: 'ResultsAccordion',
    components: { ResultsAccordionBody, ResultsAccordionHeading, SlideUpDown },
    props: ['results'],
    data: () => {
      return {
        activeAccordion: null
      }
    },
    mounted() {
      let firstTypeWithResults = _.findKey(this.results, (severity) => {
        return severity.data.length;
      });

      this.activeAccordion = !_.isUndefined(firstTypeWithResults) ? firstTypeWithResults : null;
    }
  };
</script>
