<template>
  <div class="flex items-center py-2">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'ListItem'
  };
</script>
