<template>
  <button class="btn flex items-center justify-center trans-all-15ms"
     @click="disabled ? false : clicked()"
     :class="{'btn-disabled': disabled}">
    <loading-bars v-if="disabled" color="#fff" />
    <slot v-else />
  </button>
</template>

<script>
import Icon from './Icon';
import LoadingBars from "./LoadingBars";
export default {
  name: 'Btn',
  components: {LoadingBars, Icon },
  props: {
    disabled: {
      default: false
    }
  },
  methods: {
    clicked() {
      this.$ga.event('button', 'clicked', 'text', this.$slots.default[0].text);
      this.$emit('clicked');
    }
  }
};
</script>

<style lang="scss">
  .btn {
    @apply inline-block w-full;
    @apply text-white text-sm uppercase tracking-wide leading-loose font-semibold text-center;
    @apply px-5 py-2;
    @apply border-transparent border border-solid rounded;
    will-change: transform;

    @screen md {
      &:hover {
        @apply shadow-sm opacity-75;
      }

      &:focus {
        @apply outline-none;
      }
    }

    @screen lg {
      @apply w-auto;
    }

    &:not(.btn-disabled) {
      @apply cursor-pointer;
    }

    &.btn-disabled {
      @apply pointer-events-none;
    }

    &.btn-white {
      @apply border-white;
    }

    &.btn-orange {
      @apply bg-orange border-orange;
    }

    &.btn-orange-secondary {
      @apply border border-orange text-orange;
    }

    &.btn-orange-tertiary {
      @apply text-orange underline;
    }

    &.btn-blue {
      @apply bg-blue border-blue;
    }

    &.btn-blue-secondary {
      @apply border border-blue text-blue;
    }

    &.btn-blue-tertiary {
      @apply text-blue underline;
    }

    &.btn-blue-darker {
      @apply bg-blue-darker border-blue-darker;
    }
  }
</style>
