<template>
  <portal to="modals">
    <transition enter-active-class="animated bounceIn"
                leave-active-class="animated bounceOut">
      <div v-if="isOpen" class="modal-container">
        <slot></slot>
      </div>
    </transition>
    <transition enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut">
      <div v-if="isOpen">
        <div class="fixed pin bg-black opacity-25 z-10" @click="isOpen = false"></div>
      </div>
    </transition>
  </portal>
</template>

<script>
  export default {
    name: 'Modal',
    props: {
      id: {
        required: true
      }
    },
    data() {
      return {
        isOpen: false
      }
    },
    mounted() {
      window.bus.$on('open-modal-' + this.id, () => { this.isOpen = true });
      window.bus.$on('close-modal-' + this.id, () => { this.isOpen = false });
    }
  };
</script>

<style lang="scss" scoped>
  .modal-container {
    @apply fixed z-20 bg-white p-4 rounded;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
