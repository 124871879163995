<template>
  <div class="text-blue-dark cursor-pointer">
    <div class="flex p-3 shadow-sm align-center">
      <icon name="down" class="icon trans-all-15ms" :class="{ 'is-active': isActive }" />
      <div class="flex items-center flex-grow pl-3">
        <span class="font-bold uppercase"><slot></slot></span>
        <small class="pl-2 text-grey text-xs font-bold">({{ count }})</small>
      </div>
      <tooltip v-if="tip" class="px-2 flex items-center" position="left" :text="tip">
        <icon name="question" class="opacity-50" />
      </tooltip>
    </div>
  </div>
</template>

<script>
  import Icon from '../partials/Icon';
  import Tooltip from '../partials/Tooltip';

  export default {
    components: { Tooltip, Icon },
    name: 'ResultsAccordionHeading',
    props: ['count', 'tip', 'is-active']
  };
</script>

<style scoped lang="scss">
  .icon.is-active {
    transform: rotate(180deg);
  }
</style>
