import VueStorage from './helpers/VueStorage';

window.bus = new Vue();
window.baseUrl = 'https://api.exploitsentry.com';
window.apiUrl = `${window.baseUrl}/api/v1`;
window.echoServer = `${window.baseUrl}/`;
window.vueStorage = new VueStorage();
window.io = require('socket.io-client');
window.googleCaptchaSiteKey = '6LeEQKQUAAAAAOxypSoWXV4dw4quPvWelxIjDfvY';
window.googleAnalyticsKey = 'UA-140851268-1';
window.animateInClass = 'animate-fade-in-up';
window.isPrendering = !_.isUndefined(window.__PRERENDER_INJECTED) &&
                      !_.isUndefined(window.__PRERENDER_INJECTED.IS_PRERENDERING) &&
                      window.__PRERENDER_INJECTED.IS_PRERENDERING == true;
