<template>
  <card class="md:pb-12">
    <notice v-if="errors != false" type="error" :messages="errors.all()" />
    <form @submit.prevent="submit" class="md:flex items-center shadow-hard bg-white rounded">
      <icon name="world" :hide-on-mobile="true" class="md:inline-flex pl-3" />
      <input name="url" :disabled="$wait.is('guest-scan')" class="h-full flex-grow-2 md:w-3/4 text-lg md:text-sm" type="url" v-focus v-model="form.url" v-validate required placeholder="https://my-website.com" />
      <input type="hidden" name="token" v-model="form.token">
      <google-recaptcha ref="recaptcha" :sitekey="window.googleCaptchaSiteKey" :action="recaptchaAction" @google_captcha_token="updateGoogleToken" />
      <btn :disabled="$wait.is('guest-scan')" class="text-lg md:text-base rounded-tr-none rounded-tl-none md:rounded-bl-none md:rounded-tr btn-orange md:w-1/4 w-full">
        Scan
      </btn>
    </form>
    <scan v-if="scanId != false" class="mt-8 px-4 md:px-0 pb-6" :id="scanId" />
  </card>
</template>

<script>
import Btn from '../partials/Btn.vue';
import Card from '../partials/Card';
import ResultsAccordion from './ResultsAccordion';
import Icon from '../partials/Icon';
import ProgressBar from '../partials/ProgressBar';
import Scan from './Scan';
import Notice from '../partials/Notice';
import Validate from '../../mixins';
import GoogleRecaptcha from 'vue2-recaptchav3';

export default {
  components: { Notice, Scan, ProgressBar, Icon, ResultsAccordion, Card, Btn, GoogleRecaptcha },
  name: 'GuestScanForm',
  mixins: [Validate],
  data() {
    return {
      form: {
        url: '',
        token: null,
      },
      scanId: false,
      doScan: false,
      recaptchaAction: 'guest_scan'
    };
  },
  methods: {
    updateGoogleToken(payload) {
      this.form.token = payload.response_token;
      if(this.doScan){
        this.doScanAction();
      }
    },
    submit() {
      this.scanId = false;
      if(!this.doScan){
        this.doScanAction();
        this.doScan = true;
      } else {
        this.doScan = true;
        let date = new Date();
        this.recaptchaAction = 'guest_scan_' + date.getUTCMilliseconds() + '_' + date.getMinutes() + '_' + date.getHours() + '_' + date.getSeconds();
        // this.$refs.recaptcha.generateToken();
      }

    },
    doScanAction(){
      this.validate().then(() => {
        return this.$store.dispatch(window.store.STORE_NEW_GUEST_SCAN, this.form)
      }).then((response) => {
        this.scanId = response.data.data.id;
      }).catch((errors) => {
        _.forEach(errors, (error) => {
          this.errors.add(error);
        });
      });
    }
  },
};
</script>
